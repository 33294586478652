import { MuiThemeProvider } from '@material-ui/core/styles';
import i18next from 'i18next';
import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import createTheme from './config/theme';
import * as serviceWorker from './serviceWorker';

// import i18n (needs to be bundled ;))
import './i18n';
import './index.css';

import { config } from './modules/base/models/Config';
import { IContactInfo } from './modules/base/models/ContactInfo/interface';
import { ITheme } from './modules/base/models/Theme';
import { user } from './modules/userManagement/models/User';

export default class A365DealerPortal {
  public user = user;
  public config = config;

  public updateToken(token: string) {
    user.updateToken(token);
  }

  public setBaseUrl(url: string) {
    config.setBaseUrl(url);
  }

  public setTheme(newTheme: ITheme) {
    config.setTheme(newTheme);
  }

  public setContactInfo(contactInfo: IContactInfo) {
    config.setContactInfo(contactInfo);
  }

  public hideWrapper(hide: boolean) {
    config.setHideWrapper(hide);
  }

  public useHashRouting(useHash: boolean) {
    config.setUseHashRouting(useHash);
  }

  public setTitle(title: string) {
    config.setTitle(title);
  }

  public setFavIcon(path: string) {
    config.setFavIcon(path);
  }

  public setClientId(clientId: string) {
    config.setClientId(clientId);
  }

  public setAuthority(authority: string) {
    config.setAuthority(authority);
  }

  public setPostLogoutRedirectUri(postLogoutRedirectUri: string) {
    config.setPostLogoutRedirectUri(postLogoutRedirectUri);
  }

  public setKnownAuthorities(knownAuthorities: string) {
    config.setKnownAuthorities(knownAuthorities);
  }

  public setRedirectURI(redirectURI: string) {
    config.setRedirectURI(redirectURI);
  }

  public setScope(scope: string) {
    config.setScope(scope);
  }

  public setDisplayPortalLoginButton(displayPortalLoginButton: string) {
    config.setDisplayPortalLoginButton(displayPortalLoginButton);
  }

  public setADOption(ADOption: string) {
    config.setADOption(ADOption);
  }

  public setLanguages(languages: string | null | string[]) {
    if (languages) {
      if (typeof languages === 'string') {
        config.setLanguages(languages.split(',').map(l => l.trim()));
      } else {
        config.setLanguages(languages);
      }
    }
  }

  public setDefaultNameSpace(ns: string | null) {
    i18next.loadNamespaces('locales');
    if (ns && ns.trim() !== '') {
      i18next.setDefaultNamespace(ns);
    }
  }

  public privacyLink(url: string) {
    if (url && url.trim() !== '') {
      config.setPrivacyLink(url);
    }
  }

  public privacyText(text: string) {
    if (text && text.trim() !== '') {
      config.setPrivacyText(text);
    }
  }

  public async render(id = 'root', callback?: () => void) {
    const theme = createTheme();

    ReactDOM.render(
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>,
      document.getElementById(id),
      callback
    );
  }
}

serviceWorker.register({
  onUpdate: async registration => {
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  }
});
