import { ISite } from '../helpers/typeHelpers';
import { config } from '../modules/base/models/Config';
import deviceSiteMap from '../modules/device/config/siteMap';
import partsSiteMap from '../modules/parts/config/siteMap';
import serviceSiteMap from '../modules/service/config/siteMap';
import AdminSiteMap from '../modules/userManagement/config/siteMap';
import { user } from '../modules/userManagement/models/User';
import warrantySiteMap from '../modules/warranty/config/siteMap';

function flatten(arr: ISite[]) {
  return arr ? arr.reduce((result: string[], item: ISite) => [
      ...result,
      { label: item.label,
        path: item.path },
      ...flatten(item.children)
  ], []) : [];
}

const getSiteMap = () => {
  const siteMap: ISite[] = [];
  if (user.hasRole(['representative', 'authorizedUser', 'admin', 'systemUser'])) {
    if (user.hasAccessLevel(['PartsBackOrders', 'PartsOrders', 'PartsQuotation',  'PartsInvoices', 'ReturnOrders',
    'PartInquiry', 'PartsTransactions', 'All'])) {
    siteMap.push({
      label: 'Parts',
      path: user.partsPath(),
      children: partsSiteMap,
      showInMenu: true,
      image: config.theme.frontPagePartsImage || 'images/Parts.jpg'
    });
    }
  }

  if (user.hasRole(['representative', 'authorizedUser', 'admin', 'systemUser'])) {
    if (user.hasAccessLevel(['DeviceStatus', 'DeviceInquiries',
     'DeviceBackOrders', 'DeviceOrders', 'DeviceInvoices' , 'DeliveryReports', 'AllDevices', 'DealerDevices', 'DeviceQuotation', 'All'])) {
    siteMap.push({
      label: 'Device',
      path: user.devicePath(),
      children: deviceSiteMap,
      showInMenu: true,
      image: config.theme.frontPageDeviceImage || 'images/Device.jpg'
    });
    }
  }

  if (user.hasRole(['representative', 'authorizedUser', 'admin', 'systemUser'])) {
    if (user.hasAccessLevel(['ServiceCampaigns', 'Case', 'RegisterMaintenance', 'All'])) {
    siteMap.push({
      label: 'Service',
      path: user.servicePath(),
      children: serviceSiteMap,
      showInMenu: true,
      image: config.theme.frontPageServiceImage || 'images/Service.jpg'
    });
    }
  }

  if (user.hasRole(['representative', 'authorizedUser', 'admin', 'systemUser'])) {
    if (user.hasAccessLevel(['WarrantyClaims', 'All'])) {
    siteMap.push({
      label: 'Warranty',
      path: '/warranty',
      children: warrantySiteMap,
      showInMenu: true,
      image: config.theme.frontPageWarrantyImage || 'images/Warranty.jpg'
    });
    }
  }

  if (user.hasRole(['admin'])) {
    siteMap.push({
      label: 'Administrator',
      path: '/admin',
      children: AdminSiteMap,
      showInMenu: true,
      image: config.theme.frontPageAdminImage || 'images/Admin.jpg'
    });
  }

  return siteMap;
};

export const flatSiteMap = (flatten(getSiteMap() as ISite[]));
export default getSiteMap;
