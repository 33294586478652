import { Configuration, LogLevel } from '@azure/msal-browser';
import { config } from '../../base/models/Config';

export const getMsalConfig: () => Configuration = () => {
  const AzureActiveDirectoryAppClientId = config.clientId as string;
  const AuthorityAzureB2C = config.authority as string;
  const PostLogOutRedirectUri = config.postLogoutRedirectUri as string;
  const knownAuthorities = config.knownAuthorities as string;
  const redirectUri = config.redirectUri as string;

  return {
    auth: {
      clientId: AzureActiveDirectoryAppClientId,
      authority: AuthorityAzureB2C,
      postLogoutRedirectUri: PostLogOutRedirectUri,
      knownAuthorities: knownAuthorities ? [knownAuthorities] : [],
      redirectUri: redirectUri
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };
};
