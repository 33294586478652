import { ISite } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';

const serviceSiteMap: ISite[] = [];
if (user.hasAccessLevel(['ServiceCampaigns', 'All'])) {
  serviceSiteMap.push({
      label: 'Service Campaigns',
      path: '/service/campaigns',
      children: [],
      showInMenu: true
    });
  }
if (user.hasAccessLevel(['Case', 'All'])) {
    serviceSiteMap.push({
        label: 'Case',
        path: '/service/cases',
        children: [],
        showInMenu: true
      },
      {
        label: 'Create Case',
        path: '/service/create-case',
        children: [],
        showInMenu: false
      });
    }
if (user.hasAccessLevel(['RegisterMaintenance', 'All'])) {
  serviceSiteMap.push({
    label: 'Register Schedule Service',
    path: '/service/register-maintenance',
    children: [],
    showInMenu: true
  },
    {
      label: 'Update schedule service',
      path: '/service/register-maintenance/update-service-plan/id',
      children: [],
      showInMenu: false
    });
}
export default serviceSiteMap;
