import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';

import { Omit } from '../helpers/typeHelpers';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing()
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface IProps extends Omit<SnackbarProps, 'classes' | 'open'> {
  variant: string;
  duration?: number;
  message: string;
  fixed?: boolean;
  forwardCloseEvent: (event: React.MouseEvent<HTMLElement, MouseEvent> | React.SyntheticEvent<any, Event>) => void;
  index?: number;
}

const Message = ({
  variant,
  message,
  forwardCloseEvent,
  duration,
  className,
  style,
  fixed,
  index,
  ...props
}: IProps) => {
  const Icon = variantIcon[variant];
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  function handleClose(event: React.MouseEvent<HTMLElement, MouseEvent> | React.SyntheticEvent<any, Event>,
                       reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    forwardCloseEvent(event);
    setOpen(false);
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      autoHideDuration={duration || 12000}
      onClose={handleClose}
      {...props}
    >
      {fixed ? (
        <SnackbarContent
          className={`${classes[variant]} ${className}`}
          message={(
            <span id='client-snackbar' className={classes.message}>
              <Icon className={`${classes.icon} ${classes.iconVariant}`} />
              {message}
            </span>
          )}
          style={
            {
             marginTop:index! * 80
            }}
        />
        ) : (
        <SnackbarContent
          className={`${classes[variant]} ${className}`}
          message={(
            <span id='client-snackbar' className={classes.message}>
              <Icon className={`${classes.icon} ${classes.iconVariant}`} />
              {message}
            </span>
            )}
          action={[
            (
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={handleClose}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            )
          ]}
          style={
            {
             marginTop:index! * 80
            }}
        />
      )}
    </Snackbar>
  );
};

export default (Message);
