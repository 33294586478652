import { types } from 'mobx-state-tree';

export default types.model('Dealer', {
  id: types.identifier,
  code: types.maybeNull(types.string),
  name: types.string,
  externalId: types.maybeNull(types.string),
  customerAccount: types.maybeNull(types.string),
  currencyCode: types.maybeNull(types.string)
});
